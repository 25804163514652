$trans: all 0.6s ease;
$brand: #52246E;
$text: #747482;
/*
==============================
Fonts
==============================
*/

$ff:   'Mont Demo', sans-serif;
$reg: 'Gill-Sans-Nova-Regular', sans-serif;
$bold: 'Gill-Sans-Nova-Bold', sans-serif;
$bold-it: 'Gill-Sans-Nova-Bold-Italic', sans-serif;
$light: 'Gill-Sans-Nova-Light', sans-serif;
$light-it: 'Gill-Sans-Nova-Light-Italic', sans-serif;
