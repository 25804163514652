@import 'system/variables';
@import 'system/mixin';
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.32);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounceIn {
  // from,
  // 20%,
  // 40%,
  // 60%,
  // 80%,
  // to {
  //   -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  //   animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  // }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
html {
  overflow-x: hidden;
}
body.order-show {
  // position: fixed;
  // overflow: hidden;
  width: 100%;
// height: 100%;
  // .hero {
  //   transform: translateY(-30%);

  //   .content-width {
  //     opacity: 0;
  //   }
  // }

  .order {
    transform: translateY(0%);
  }
}

.header {
  position: fixed;
  // background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  top: 0;
  width: 100%;
  z-index: 99;
  top: 0;
  padding: 20px 0;
  background: rgba(12, 12, 17, 0.72);
  // @include vw($padding-top: 100);

  @include respond-to(xs) {
    // padding-top: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #000000;
  }

  .site-width {
    display: flex;
    justify-content: space-between;
    align-items: top;
  }

  .logo {
    @include vw($height: 60, $width: 277);
    display: block;
    background: url(/images/logo-white.png) no-repeat center center;
    background-size: contain;
    // svg{
    //   width: 100%;
    // }
    @include respond-to(xs) {
      height: 43px;
      width: 90px;
      background: url(/images/site-logo.png) no-repeat center center;
      background-size: contain;
    }
  }
}

.hero {
  background: #000;
  height: 100vh;
  @include vw($min-height: 1080, $padding-top: 245);
  background: #000 url('/images/hero.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  transition: transform 1s ease-out;

  @include respond-to(xs) {
    min-height: 812px;
    padding-top: 147px;
  }

  // transition: transform 0.6s ease-out;
  .content-width {
    opacity: 1;
    transition: opacity 1s ease-out;
  }

  .title {
    @include vw($margin-bottom: 60);
    @include respond-to(xs) {
      // font-size: 60px;s
      margin-bottom: 30px;

      line-height: 1;
      font-size: 51px;
    }
  }

  &__subtitle {
    font-family: $ff;
    @include vw($font-size: 36, $margin-bottom: 170, $max-width: 900);
    width: 100%;
    color: $brand;
    line-height: 1.4;

    @include respond-to(xs) {
      font-family: $bold;
      font-size: 26px;
      margin-bottom: 120px;
    }
  }

  &__content {
    position: relative;
    z-index: 3;
    &-sub {
      font-family: $ff;
      @include vw($font-size: 36, $margin-bottom: 75);
      color: #fff;

      @include respond-to(xs) {
        font-family: $bold;
        font-size: 26px;
        width: 79%;
        line-height: 1.1;
        margin-bottom: 15px;
      }
    }

    &-info {
      @include vw($font-size: 24, $width: 460, $margin-bottom: 22);
      font-family: $light;
      max-width: 100%;

      @include respond-to(xs) {
        font-size: 16px;
        margin-bottom: 39px;
      }
    }
  }
  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0;
    width: 100%;
    @include vw($width: 600, $margin-bottom: 20);
    max-width: 100%;

    &-left {
      display: flex;
      align-items: center;
      @include vw($padding-right: 30);
      @include respond-to(xs) {
        padding-right: 30px;
      }
      img {
        @include vw($width: 80, $margin-right: 15);
        @include respond-to(xs) {
          width: 42px;
          margin-right: 10px;
        }
      }
      span {
        color: #fff;
        font-family: $light;
        @include vw($font-size: 24);
        @include respond-to(xs) {
          font-family: $reg;
          font-size: 14px;
        }
      }
    }
    &-right {
      @include vw($width: 110);
      @include respond-to(xs) {
        width: 70px;
      }
      img {
        width: 100%;
      }
    }
  }
  .arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @include vw($bottom: 30, $width: 54, $height: 54);
    display: flex;
    align-items: center;
    justify-content: center;
    background: $brand;
    border-radius: 50%;
    // transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
    animation: bounce 2s infinite;
    &:hover {
      transform: translate(-50%, 10%);
    }

    @include respond-to(xs) {
      display: none;
    }
  }
}

.stars {
  background: linear-gradient(to bottom, #030104, #0c0c11);
  position: relative;

  &__bg {
    width: 80%;
    margin: 0 auto;
    background: url('/images/stars.png') top center no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.clouds {
  @include vw($padding-top: 90, $padding-bottom: 90);
  position: relative;

  @include respond-to(xs) {
    padding-top: 30px;
    display: none;
  }

  .content-width {
    display: flex;
    justify-content: space-between;

    @include respond-to(xs) {
      flex-wrap: wrap;
    }
  }

  &__item {
    flex-basis: 30%;
    text-align: center;

    @include respond-to(xs) {
      flex-basis: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin-bottom: 40px;
    }

    &:nth-child(2) {
      .clouds__img {
        @include vw($margin: 15 auto 26);

        @include respond-to(xs) {
          margin-top: 5px;
          margin-bottom: 0;
        }

        img {
          @include respond-to(xs) {
            width: 70%;
          }
        }
      }
    }

    &:nth-child(3) {
      .clouds__img {
        @include vw($margin-bottom: 17);

        img {
          @include respond-to(xs) {
            width: 70%;
          }
        }
      }
    }
    &:nth-child(2) {
      img {
        animation-delay: 1.5s;
      }
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
  }

  &__img {
    width: 70%;
    @include vw($margin: 0 auto 5);

    @include respond-to(xs) {
      margin: 0 auto 5px;
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $brand;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.04);
    }

    img {
      animation: float 6s ease-in-out infinite;
      @include respond-to(xs) {
        width: 60%;
      }
    }
  }

  &__descr {
    font-family: $ff;
    color: #fff;
    @include vw($font-size: 36);

    @include respond-to(xs) {
      font-size: 26px;
      margin-bottom: 20px;
    }
  }
}

.pills {
  position: relative;
  @include vw($padding-top: 200);

  @include respond-to(xs) {
    padding-top: 100px;
  }

  .title {
    line-height: 1;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      @include vw($width: 400, $height: 400, $top: -210, $left: -137);
      display: block;
      background: url(/images/moon.png) center center no-repeat;
      background-size: cover;

      @include respond-to(xs) {
        width: 210px;
        height: 270px;
        top: -155px;
        left: -43px;
      }
    }
  }

  &__content {
    position: relative;
  }

  &__bg {
    @include vw($width: 1000, $height: 1240);
    background: url(/images/pills-bg.png) center center no-repeat;
    background-size: cover !important;
    opacity: 0.19;
    position: absolute;
    z-index: 0;
    @include vw($top: -120, $left: -270);

    @include respond-to(xs) {
      width: 280px;
      height: 270px;
      top: -26px;
      left: -30px;
      opacity: 0.2;
    }
  }

  &__container {
    position: relative;
    @include vw($padding-bottom: 700);

    @include respond-to(xs) {
      padding-bottom: 475px;
    }
  }

  &__img {
    @include vw($width: 950, $margin: 40 0 0 148);

    @include respond-to(xs) {
      width: 255px;
      margin: 93px 0 0 45px;
    }

    img {
      width: 100%;
    }
  }

  .dot {
    @include vw($width: 70, $height: 70, $top: 279, $left: 168);
    position: absolute;
    @include respond-to(xs) {
      top: 82px;
      left: 46px;
      width: 11px;
      height: 11px;
    }

    &__circle {
      display: block;
      border-radius: 50%;
      cursor: pointer;
      @include vw($width: 8, $height: 8);
      background: #fff;
      box-shadow: 0px 0px 6px 4px rgba(106, 97, 122, 0.6);
      position: absolute;
      @include vw($width: 35, $height: 35);
      transform: translate(-26%, -26%);
      &:before {
        content: '';
        position: absolute;
        @include vw(
          $width: 70,
          $height: 70,
          $margin-left: -35,
          $margin-top: -35
        );
        display: block;
        background: url('/images/touch-border.png') center center no-repeat;
        background-size: 98%;
        left: 50%;
        top: 50%;
        // transform: translate(-50%, -50%) ;
        animation: rotate 6s linear infinite;
        @include respond-to(xs) {
          width: 20px;
          height: 20px;
          margin-left: -10px;
          margin-top: -10px;
        }
      }
      @include respond-to(xs) {
        width: 14px;
        height: 14px;
      }
    }

    &__line {
      &--hor {
        // @include vw($height: 2);
        height: 2px;
        background: #fff;
        display: block;
        position: absolute;
      }

      &--vert {
        // @include vw($width: 2);
        width: 2px;
        background: #fff;
        display: block;
        position: absolute;
        // transition: height 0.3s ease;
      }
    }

    &--1 {
      &.is-hide {
        .dot__line {
          &--1 {
            @include vw($width: 0);
            transition: width 0s ease 0s;
          }
          &--2 {
            @include vw($height: 0);
            transition: height 0s ease 0s;
            @include respond-to(xs) {
              height: 0;
            }
          }
          &--3 {
            @include vw($width: 0);
            transition: width 0s ease 0s;
            @include respond-to(xs) {
              left: 4px;
              top: 11px;
              height: 230px;
            }
          }
        }

        .dot__icon {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
        .dot__title {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
        .dot__text {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
      }
      .dot__line {
        &--1 {
          @include vw($width: 166);
          position: absolute;
          left: 0;
          top: 10%;
          transform: translate(-100%, -50%);
          transition-delay: 5s;
          transition: width 0.6s ease 0s;
          @include respond-to(xs) {
            display: none;
          }
        }

        &--2 {
          @include vw($left: -166, $top: 6, $height: 749);
          transition: height 0.6s ease 0.6s;
          @include respond-to(xs) {
            left: 2px;
            top: 11px;
            height: 297px;
            transition: height 0.6s ease;
          }
        }
        &--3 {
          @include vw($left: -165, $top: 752, $width: 124);
          transition: width 0.6s ease 1.2s;
          @include respond-to(xs) {
            display: none;
          }
        }
      }
      .dot__icon {
        opacity: 1;
        transition: opacity 0.6s ease 1.8s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
      .dot__title {
        opacity: 1;
        transition: opacity 0.6s ease 1.8s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
      .dot__text {
        opacity: 1;
        transition: opacity 0.6s ease 1.8s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
      .dot__content {
        position: absolute;
        @include vw($top: 690, $left: -20);
        @include respond-to(xs) {
          top: 253px;
          left: -98px;
          // width: 350px;
        }
      }

      .dot__icon {
        @include vw($padding-top: 10);
        @include respond-to(xs) {
          width: 90px;
          height: 115px;
          border: 0;
          margin: 56px 0 10px 62px;
          padding: 10px 0 0;
          flex-basis: 80px;
          cursor: pointer;
        }

        img {
          width: 78%;
          @include respond-to(xs) {
            width: 100%;
          }
        }
      }
    }

    &--2 {
      @include vw($top: 383, $left: 272);
      @include respond-to(xs) {
        top: 58px;
        left: 103px;
      }
      &.is-hide {
        .dot__line {
          &--2 {
            @include vw($height: 0);
            transition: height 0s ease 0s;
            @include respond-to(xs) {
              height: 0;
              transition: height 0s ease 0s;
            }
          }
          &--3 {
            @include vw($width: 0);
            transition: width 0s ease 0s;
          }
        }

        .dot__icon {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
        .dot__title {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
        .dot__text {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
      }
      .dot__line {
        &--2 {
          @include vw($height: 437, $left: 10);
          position: absolute;
          // left: 50%;
          transform: translate(-50%, 0%);
          transition: height 0.6s ease;
          @include respond-to(xs) {
            height: 181px;
            transition: height 0.6s ease;
            left: 3px;
          }
        }
        &--3 {
          @include vw($left: 10, $top: 436, $width: 120);
          transition: width 0.6s ease 0.6s;
          @include respond-to(xs) {
            display: none;
          }
        }
      }

      .dot__content {
        position: absolute;
        @include vw($top: 382, $left: 145);

        @include respond-to(xs) {
          top: 132px;
          left: -100px;
        }
      }

      .dot__icon {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;

          width: 90px;
          height: 115px;
          border: 0;
          margin: 56px 0 10px 62px;
          padding: 10px 0 0;
          flex-basis: 80px;
          cursor: pointer;
        }
        img {
          height: 92%;
          @include respond-to(xs) {
            height: auto;
          }
        }
      }
      .dot__title {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
      .dot__text {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
    }

    &--3 {
      @include vw($top: 543, $left: 550);
      @include respond-to(xs) {
        top: 139px;
        left: 167px;
      }
      &.is-hide {
        .dot__line {
          &--2 {
            @include vw($height: 0);
            transition: height 0s ease 0s;
            @include respond-to(xs) {
              height: 0;
              transition: height 0s ease 0s;
            }
          }
          &--3 {
            @include vw($width: 0);
            transition: width 0s ease 0s;
          }
        }

        .dot__icon {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
        .dot__title {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
        .dot__text {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
      }
      .dot__line {
        &--2 {
          @include vw($height: 154, $left: 9);
          position: absolute;
          transform: translate(-50%, 0%);
          transition: height 0.6s ease;
          @include respond-to(xs) {
            height: 305px;
            left: 4px;
            transition: height 0.6s ease;
          }
        }

        &--3 {
          @include vw($left: 10, $top: 153, $width: 254);
          transition: width 0.6s ease 0.6s;
          @include respond-to(xs) {
            display: none;
          }
        }
      }

      .dot__content {
        position: absolute;
        @include vw($top: 85, $left: 280);

        @include respond-to(xs) {
          top: 255px;
          left: -100px;
        }
      }

      .dot__icon {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
          width: 90px;
          height: 115px;
          border: 0;
          margin: 56px 0 10px 62px;
          padding: 10px 0 0;
          flex-basis: 80px;
          cursor: pointer;
        }
        img {
          height: 80%;
          @include respond-to(xs) {
            height: auto;
          }
        }
      }
      .dot__title {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
      .dot__text {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
    }

    &--4 {
      @include vw($top: 340, $left: 820);
      @include respond-to(xs) {
        top: 97px;
        left: 245px;
      }
      &.is-hide {
        .dot__line {
          &--2 {
            @include vw($height: 0);
            transition: height 0s ease 0s;
            @include respond-to(xs) {
              height: 0;
              transition: height 0s ease 0s;
            }
          }
          &--3 {
            @include vw($width: 0);
            transition: width 0s ease 0s;
          }
        }

        .dot__icon {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
        .dot__title {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
        .dot__text {
          opacity: 0;
          transition: opacity 0s ease 0s;
        }
      }
      .dot__line {
        &--2 {
          @include vw($height: 154, $left: 9);
          position: absolute;
          transform: translate(-50%, 0%);
          transition: height 0.6s ease;
          @include respond-to(xs) {
            height: 210px;
            left: 4px;
            transition: height 0.6s ease;
          }
        }

        &--3 {
          @include vw($left: 10, $top: 153, $width: 254);
          transition: width 0.6s ease 0.6s;
          @include respond-to(xs) {
            display: none;
          }
        }
      }

      .dot__content {
        position: absolute;
        @include vw($top: 85, $left: 280);

        @include respond-to(xs) {
          top: 155px;
          left: -100px;
        }
      }

      .dot__icon {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
          width: 90px;
          height: 115px;
          border: 0;
          margin: 56px 0 10px 62px;
          padding: 10px 0 0;
          flex-basis: 80px;
          cursor: pointer;
        }
        img {
          height: 80%;
          @include respond-to(xs) {
            height: auto;
          }
        }
      }
      .dot__title {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
      .dot__text {
        opacity: 1;
        transition: opacity 0.6s ease 1.2s;
        @include respond-to(xs) {
          transition: opacity 0.6s ease 0.6s;
        }
      }
    }
    /// end 4

    &.is-hide {
      .dot__line--hor {
        width: 0;
      }
      .dot__line--vert {
        height: 0;
      }
      .dot__icon {
        opacity: 0;
      }
      .dot__descr {
        opacity: 0;
      }
      //   @include respond-to(xs) {
      //     .dot__line--vert {
      //       height: 0;
      //     }
      //     .dot__icon {
      //       transform: translateY(10px);
      //       opacity: 0;
      //     }
      //     .dot__descr {
      //       transform: translateY(10px);
      //       opacity: 0;
      //     }
      //   }
    }
    &__content {
      @include vw($width: 419);
      display: flex;
      @include respond-to(xs) {
        flex-wrap: wrap;
        width: 220px;
      }
    }

    &__icon {
      @include vw(
        $flex-basis: 110,
        $width: 110,
        $height: 110,
        $border: 4 solid $brand,
        $margin-right: 39
      );
      border-radius: 50%;
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include respond-to(xs) {
        // flex-basis: 90px;
        width: 90px;
        height: 115px;
        margin-bottom: 10px;
        transition: all 0.5s ease;
        display: none;
        cursor: pointer;
      }
    }

    &__descr {
      @include vw($padding-top: 22);

      @include respond-to(xs) {
        padding-top: 0px;
        display: none;
      }
    }

    &__title {
      text-transform: uppercase;
      font-family: $bold;
      @include vw($font-size: 28);

      @include respond-to(xs) {
        font-size: 26px;
        font-family: $reg;
        text-align: center;
      }
    }

    &__text {
      @include vw($font-size: 18);
      font-family: $light;
      line-height: 1.2;
      span {
        display: block;
        @include vw($margin-top: 5);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      @include respond-to(xs) {
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
      }
    }
  }
}
.shooting-star-1 {
  position: absolute;
  @include vw($right: 80, $top: 0, $width: 606);
  @include respond-to(xs) {
    display: none;
  }
}
.fact {
  background: linear-gradient(to bottom, #0c0c11, #0c0c11);
  @include vw($padding-top: 145, $padding-bottom: 100);

  @include respond-to(xs) {
    padding-top: 31px;
  }
  .content-width {
    // &:hover{

    // .fact__img{
    //     transform:  scale(1.33) ;
    //     // transform: scale(1.33) rotate(-37deg);
    //   }
    // }
  }
  .title {
    line-height: 1;
    @include vw($margin-bottom: 50);

    @include respond-to(xs) {
      margin-bottom: 10px;
    }
  }

  &__subtitle {
    @include vw($font-size: 32);
    font-family: $reg;

    @include respond-to(xs) {
      font-size: 18px;
      width: 80%;
    }
  }

  &__img {
    @include vw($width: 782, $margin: 140 auto 95);
    animation: scale 10s ease infinite alternate;

    // transition: all 5s ease;
    // transform: scale(1) rotate(0);
    transform: scale(1);
    @include respond-to(xs) {
      width: 94%;
      margin: 60px auto 24px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }

  &__link {
    font-family: $light;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @include vw($font-size: 32);
    color: #fff;
    transition: all 0.4s ease;
    z-index: 99;
    @include respond-to(xs) {
      font-size: 16px;
    }

    &-icon {
      @include vw($width: 54, $height: 54, $margin-left: 30);
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.4s ease;

      @include respond-to(xs) {
        width: 32px;
        height: 32px;
        margin-left: 10px;
      }
      img {
        transform: rotate(-90deg);
        width: 43%;
      }
    }

    &:hover {
      color: #fff;
      opacity: 0.8;

      .fact__link-icon {
        transform: translateX(10%);
      }
    }
  }
}

.light {
  background: linear-gradient(to bottom, #0c0c11, #15151c);
  @include vw($margin-top: -1);
  position: relative;

  @include respond-to(xs) {
    overflow: hidden;
  }

  .site-width {
    position: relative;
    @include respond-to(xs) {
      padding: 0;
    }
  }

  &__lamp {
    @include vw($width: 1010, $margin: 0 auto, $top: -90);
    position: absolute;
    max-width: 100%;

    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    @include respond-to(xs) {
      position: relative;
      width: 82%;
      margin: 0 auto;
      top: -180px;
      position: relative;
      left: 0;
      transform: translate(0px, 0px);
      opacity: 0;
      transition: all 0.4s ease;
    }

    span {
      @include respond-to(xs) {
        color: rgba(255, 255, 255, 0.08);
        text-align: center;
        font-size: 26px;
        width: 80%;
        display: block;
        margin: -50px auto 0;
      }
    }

    img {
      width: 100%;
    }
  }

  .title {
    @include vw($padding-top: 55, $margin-bottom: 180);
    // opacity: 0;
    @include respond-to(xs) {
      padding-top: 38px;
      position: relative;
      margin-bottom: 0;
    }
    .grey {
      opacity: 0.08;
    }
    span {
      // &:first-child {
      //   @include respond-to(xs) {
      //     margin-bottom: 00px;
      //     font-size: 115px;
      //     display: block;
      //     // margin-left: -24px;
      //   }
      // }

      // &:last-child {
      //   // display: none;
      //   @include respond-to(xs) {
      //     display: block;
      //     padding-top: 8px;
      //     margin-bottom: 0;
      //     font-size: 115px;
      //     text-align: right;
      //     // margin-right: -20px;
      //     color: #fff;
      //     display: block;
      //   }
      // }
    }
    &__first {
      @include respond-to(xs) {
        font-size: 50px;
        text-align: center;
        color: rgba(255, 255, 255, 0.75);
        text-align: center;
        display: block;
      }
    }
    &__sec {
      @include respond-to(xs) {
        margin-bottom: 00px;
        font-size: 115px;
        display: block;
        // margin-left: -24px;
      }
    }
    &__third {
      @include respond-to(xs) {
        display: block;
        padding-top: 8px;
        margin-bottom: 0;
        font-size: 124px;
        text-align: right;
        color: rgba(255, 255, 255, 0.16);
        display: block;
        opacity: 1;
      }
    }
    &__fourth {
      @include respond-to(xs) {
        display: block;
        color: rgba(255, 255, 255, 0.05);
        font-size: 600px;
        opacity: 1;
        margin-top: -163px;
        opacity: 1;
      }
    }
  }

  .switch-wrap {
    display: none;
    @include respond-to(xs) {
      margin-top: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
    }

    input[type='checkbox'] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 127px;
      height: 50px;
      background: transparent;
      display: block;
      border-radius: 100px;
      position: relative;
      border: 4px solid #fff;
    }

    label:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 40px;
      height: 40px;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }

    input:checked + label {
      // background: #bada55;
    }

    input:checked + label:after {
      left: calc(100% - 1px);
      transform: translateX(-100%);
      background: #f0f947;
    }

    label:active:after {
      // width: 130px;
    }
  }

  &.on {
    .title {
      @include vw($padding-top: 55, $margin-bottom: 180);

      @include respond-to(xs) {
        padding-top: 38px;
      }

      span {
        &:last-child {
          @include respond-to(xs) {
            color: rgba(255, 255, 255, 0.08);
          }
        }
      }
    }

    .light__lamp {
      opacity: 1;
    }
  }

  &__descr {
    @include vw($margin-bottom: 30);
    opacity: 0;
    @include respond-to(xs) {
      display: none;
    }

    &-title {
      font-family: $bold;
      color: #fff;
      @include vw($font-size: 40, $margin-bottom: 10);
    }

    &-text {
      @include vw($font-size: 32, $margin-bottom: 50);
      color: #fff;
      font-family: $light;
      line-height: 1.19;
      width: 88%;

      &:last-child {
        @include vw($margin-bottom: 0);
      }
    }
  }

  &__section {
    position: relative;
    &-word {
      span {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        opacity: 0;
      }
    }
    &.first {
      @include vw($margin-bottom: 350);

      @include respond-to(xs) {
        margin-bottom: 0;
      }

      .light__section-img {
        @include vw($width: 340);

        @include respond-to(xs) {
          width: 146px;
          position: absolute;
          top: -69px;
          left: 38px;
        }

        img {
          width: 100%;
        }
      }

      .light__section-word {
        position: absolute;
        color: rgba(255, 255, 255, 0.08);
        @include vw($font-size: 400, $margin-top: 140, $letter-spacing: -12);

        @include respond-to(xs) {
          font-size: 127px;
          margin-top: -24px;
          letter-spacing: -4px;
          margin-left: 46px;
          position: static;
        }
      }
    }

    &.second {
      display: flex;
      justify-content: flex-end;

      .light__section-word {
        color: rgba(255, 255, 255, 0.08);
        @include vw($font-size: 400, $margin-top: 0, $letter-spacing: 0);

        @include respond-to(xs) {
          font-size: 124px;
          margin-top: -10px;
          letter-spacing: 0;
          margin-right: 36px;
        }
      }

      .light__section-img {
        position: absolute;
        @include vw($top: 385, $width: 200, $right: 90);

        @include respond-to(xs) {
          top: 175px;
          width: 83px;
          right: 120px;
        }

        img {
          width: 100%;
        }
      }
    }

    &.third {
      @include vw($margin-top: 50, $padding-bottom: 100);

      .light__section-word {
        color: rgba(255, 255, 255, 0.08);
        @include vw($font-size: 400, $margin-top: 0, $letter-spacing: 0);

        @include respond-to(xs) {
          font-size: 124px;
          margin-top: 177px;
          letter-spacing: 0;
          margin-left: 33px;
        }
      }

      .light__section-img {
        position: absolute;
        @include vw($top: 647, $width: 305, $left: 135);

        @include respond-to(xs) {
          top: -147px;
          width: 135px;
          left: 39px;
        }

        img {
          width: 100%;
        }
      }
    }

    &.fourth {
      display: flex;
      justify-content: flex-end;
      @include vw($padding-bottom: 440);

      @include respond-to(xs) {
        padding-bottom: 80px;
      }

      .light__section-word {
        color: rgba(255, 255, 255, 1);
        @include vw($font-size: 200, $margin-top: 0, $letter-spacing: 0);
        text-align: right;
        line-height: 1;
        span {
          // color: rgba(255, 255, 255, 1);
          // @include vw($font-size: 200, $margin-top: 0, $letter-spacing: -38);

          // line-height: 1;
        }
        @include respond-to(xs) {
          margin-top: 110px;
          letter-spacing: 0;
          font-size: 59px;
          margin-right: 35px;
        }
      }

      .light__section-img {
        position: absolute;
        @include vw($top: 448, $width: 352, $left: 390);
        opacity: 0.5;
        @include respond-to(xs) {
          top: -100px;
          width: 165px;
          left: 35px;
        }

        img {
          width: 100%;
        }
      }
    }

    &.fifth {
      text-align: center;
      @include vw($padding-bottom: 240);

      @include respond-to(xs) {
        padding-bottom: 0;
      }

      .light__section-word {
        color: rgba(255, 255, 255, 1);
        @include vw($font-size: 475, $margin-top: 0, $letter-spacing: 0);
        text-align: center;
        line-height: 1;
        // span{
        //     color: rgba(255, 255, 255, 1);
        //     @include vw($font-size: 500, $margin-top: 0, $letter-spacing: -94);
        //     text-align: center;
        //     line-height: 1;
        // }
        @include respond-to(xs) {
          font-size: 98px;
        }
      }
    }
  }
}

.feeling {
  @include vw($padding-top: 190, $margin-top: -1, $padding-bottom: 160);
  background: linear-gradient(to bottom, #15151c, #71717e);

  @include respond-to(xs) {
    padding-top: 110px;
    padding-bottom: 60px;
  }

  &__title {
    text-align: center;
    color: #fff;
    @include vw($font-size: 72, $margin-bottom: 20);

    @include respond-to(xs) {
      display: none;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    @include vw($padding-top: 47);

    @include respond-to(xs) {
      flex-wrap: wrap;
      padding-top: 0;
    }
  }

  &__left {
    flex-basis: 64%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: -5%;

    @include respond-to(xs) {
      flex-basis: 106%;
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: -6%;
    }

    img {
      width: 100%;
    }
  }

  &__right {
    flex-basis: 39%;
    @include vw($padding-top: 70);

    @include respond-to(xs) {
      padding-top: 48px;
      flex-basis: 100%;
    }
  }

  &__list {
    &-item {
      background: #767686;
      @include vw(
        $border-radius: 8,
        $min-height: 142,
        $padding: 20,
        $margin-bottom: 50
      );
      display: flex;
      align-items: center;

      @include respond-to(xs) {
        font-size: 26px;
        border-radius: 8px;
        min-height: 102px;
        padding: 15px 10px 15px 15px;
        margin-bottom: 29px;
        font-size: 26px;
      }
      &.is-visible {
        .feeling__list-icon {
          animation: bounceIn 4s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }

    &-icon {
      @include vw($width: 41, $margin-right: 30);
      flex-shrink: 0;
      // animation: bounceIn 2s  cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      // animation-timing-function: ;
      @include respond-to(xs) {
        width: 44px;
        margin-right: 9px;
        flex-shrink: 0;
      }

      img {
        width: 100%;
      }
    }

    &-text {
      color: #fff;
      @include vw($font-size: 36);
      line-height: 1.2;

      @include respond-to(xs) {
        font-size: 26px;
      }

      br {
        display: none;
      }
    }
  }
}

.bottom {
  background: linear-gradient(to bottom, #71717e, #a2a2b6);
  @include vw($margin-top: -1, $padding-top: 210);

  @include respond-to(xs) {
    padding-top: 0;
  }
}

.review {
  &__title {
    text-align: center;
    color: #fff;
    width: 80%;
    margin: 0 auto;
    line-height: 1;
    @include vw($font-size: 72, $margin-bottom: 15);

    @include respond-to(xs) {
      text-align: left;
      color: #fff;
      width: 100%;
      margin: 0 auto;
      line-height: 1;
      font-size: 51px;
    }
  }

  &__inner {
    position: relative;
    @include vw($margin-bottom: 38);
  }

  &-slider {
    &__item {
      justify-content: space-between;
      display: flex !important;
      background: #fff;
      @include vw($border-radius: 8, $padding-bottom: 90);

      @include respond-to(xs) {
        flex-wrap: wrap;
        border-radius: 8px;
        overflow: hidden;
      }
      @include respond-to(xs) {
        background: $brand;
      }
    }

    .slick-track {
      // @include vw($padding-top: 190);
      @include vw($margin-top: 100);

      // @include respond-to(xs) {
      //   padding-top: 56px;
      // }
    }

    &__img {
      // @include vw($width: 577);
      background: $brand;
      position: relative;
      @include vw($border-top-left-radius: 8, $border-bottom-left-radius: 8);
      flex-basis: 43%;
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;
      display: none;

      @include respond-to(xs) {
        flex-basis: 100%;
        border-radius: 0;
      }
      &:before {
        content: '';
        @include vw($height: 200);
        position: absolute;
        bottom: 0;
        z-index: 6;
        width: 100%;
        background: linear-gradient(to bottom, transparent, #52246e);
        @include respond-to(xs) {
          height: 100px;
        }
      }
      img {
        width: 90%;
        @include vw($margin: -260 auto 0);

        @include respond-to(xs) {
          margin: -74px auto 0;
          width: 60%;
        }
      }

      &.resize {
        img {
          width: 109%;
          margin-right: -5%;
          max-width: none;

          @include respond-to(xs) {
            width: 70%;
            margin-right: 0%;
            max-width: 100%;
          }
        }
      }

      @include respond-to(xs) {
        order: 2;
      }
    }

    &__content {
      @include vw($padding: 100 20);
      width: 70%;
      margin: 0 auto;
      @include respond-to(xs) {
        order: 1;
        padding: 40px 20px;
        background: $brand;
        padding: 40px 25px;
        width: 100%;
      }
    }

    &__header {
      @include vw($padding: 0 60, $margin-bottom: 90);
      display: flex;
      align-items: center;

      @include respond-to(xs) {
        padding: 0 20px;
        margin-bottom: 0px;
      }
    }

    &__quote {
      @include vw($flex-basis: 60, $margin-right: 20);
      flex-shrink: 0;
      flex-grow: 0;
      @include respond-to(xs) {
        flex-basis: 50px;
      }

      img {
        width: 100%;
      }
    }

    &__line {
      @include vw($height: 2);
      @include vw($margin-top: 25);
      background: #e4e4e4;
      flex-grow: 1;

      @include respond-to(xs) {
        display: none;
      }
    }

    &__text {
      @include vw($font-size: 36);
      font-family: $bold-it;
      color: #747482;
      text-align: center;
      line-height: 1.13;

      @include respond-to(xs) {
        font-size: 18px;
        color: #fff;
        line-height: 1.4;
      }
      &--lg {
        @include vw($font-size: 40, $margin-bottom: 20);
        display: block;
        @include respond-to(xs) {
          font-size: 22px;
        }
      }
      &--sm {
        @include vw($font-size: 26);
        display: block;
        @include respond-to(xs) {
          font-size: 14px;
        }
      }
    }

    &__author {
      @include vw($font-size: 26, $margin-top: 40, $padding-bottom: 40);
      font-family: $bold;
      color: $brand;
      text-align: center;
      line-height: 1.13;

      @include respond-to(xs) {
        font-size: 18px;
        color: #fff;
        margin-top: 15px;
        font-size: 26px;
        padding-bottom: 25px;
      }
    }
  }

  .arrow {
    position: absolute;
    z-index: 9;
    left: 50%;
    @include vw($bottom: 12, $padding: 5 0);
    display: flex;
    align-items: center;
    transform: translateX(-50%);
    @include respond-to(xs) {
      display: none;
    }
  }

  .slick-arrow {
    position: static;
    @include vw(
      $width: 51,
      $height: 51,
      $margin: 0 10,
      $border: 4 solid $brand
    );
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    &:before {
      content: '';
      display: block;
      position: static;
      @include vw($width: 13, $height: 25);
      opacity: 1;
    }
    &:hover {
      opacity: 0.8;
    }
  }



  .slick-prev {
    &:before {
      background: url(/images/chevron-left.svg) center center no-repeat;
      background-size: contain;
    }
  }

  .slick-next {
    &:before {
      background: url(/images/chevron-right.svg) center center no-repeat;
      background-size: contain;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;

    @include respond-to(xs) {
      display: none;
    }
  }

  &__link {
    font-family: $light;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @include vw($font-size: 32);
    color: #fff;
    transition: all 0.4s ease;

    &-icon {
      @include vw($width: 54, $height: 54, $margin-left: 30);
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.4s ease;
      img {
        transform: rotate(-90deg);
        width: 43%;
      }
    }

    &:hover {
      color: #fff;
      opacity: 0.8;

      .fact__link-icon {
        transform: translateX(10%);
      }
    }
  }
}

.footer {
  background: url(/images/footer.png) center center no-repeat;
  background-size: cover;
  position: relative;
  @include vw($margin-top: -110, $padding-top: 120);

  @include respond-to(xs) {
    margin-top: 0;
    padding-top: 0px;
    background: url(/images/footer-m.png) bottom center no-repeat;
    background-size: cover;
  }
}

.subscr {
  display: flex;
  flex-wrap: wrap;
  @include vw($width: 900, $margin: 0 auto, $padding: 390 0 340);
  text-align: center;

  @include respond-to(xs) {
    width: 100%;
    margin: 0 auto;
    padding: 234px 0 30px;
  }

  &__title {
    color: #fff;
    @include vw($font-size: 40, $margin-bottom: 30);
    font-family: $bold;
    flex-basis: 100%;

    @include respond-to(xs) {
      font-size: 26px;
    }
  }

  form {
    display: flex;
    width: 100%;
    @include vw($padding: 0 40);

    @include respond-to(xs) {
      padding: 0 0px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__input {
    border: none;
    @include vw(
      $border-radius: 24,
      $width: 540,
      $margin-right: 20,
      $padding: 0 30
    );
    max-width: 100%;
    color: $text;

    @include respond-to(xs) {
      border-radius: 24px;
      width: 100%;
      margin-right: 0;
      padding: 0 30px;
      height: 52px;
      margin-top: 8px;
      margin-bottom: 23px;
    }
  }
}

.quiz {
  &-wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    // background: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    opacity: 0;
    @include vw($padding-top: 140);
    transform: translateY(100%);
    // transform: translateY(0%);
    transition: transform 0.8s ease;

    @include respond-to(xs) {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.is-active {
      // background:  #000;
      opacity: 1;
      transform: translateY(0%);
    }

    &.fade-out {
      // background:  #000;
      opacity: 0;
    }
  }

  width: 99%;
  margin: 0 auto;
  @include vw($border: 4 solid $brand, $border-radius: 24);
  background: #fff;

  @include respond-to(xs) {
    // overflow: hidden;
    width: 85%;
  }

  &__header {
    @include vw($height: 328);
    position: relative;

    @include respond-to(xs) {
      height: 115px;
    }

    &-bg {
      background: url(/images/quiz-bg.jpg) center center no-repeat;
      background-size: cover;
      @include vw($border-top-left-radius: 20, $border-top-right-radius: 20);
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
    }

    &-win {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.8s ease-out;

      // visibility: hidden;
      &.is-visible {
        opacity: 1;
      }
    }
  }

  &__cloud {
    @include vw($width: 500, $margin: 0 0 10 0, $top: -160);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include respond-to(xs) {
      width: 60%;
      margin: 0 0 10px;
      top: 0px;
      transform: translate(-50%, -30%);
    }

    img {
      width: 100%;
    }
  }

  &__win {
    height: 100%;
    @include vw($width: 310, $margin: 0 0 10 0, $top: -80);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include respond-to(xs) {
      width: 170px;
      margin: 0 0 10px;
      top: -30px;
    }

    img {
      width: 100%;
    }
  }

  &__title {
    color: #fff;
    text-align: center;
    @include vw($font-size: 48, $padding-top: 180, $margin-bottom: 5);
    font-family: $bold;
    position: relative;

    @include respond-to(xs) {
      display: none;
    }
  }

  &__subtitle {
    position: relative;
    color: #fff;
    font-family: $light;
    text-align: center;
    @include vw($font-size: 28);

    @include respond-to(xs) {
      display: none;
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include vw(
      $width: 54,
      $height: 54,
      $top: 20,
      $right: 20,
      $border: 2 solid #fff
    );
    z-index: 2;
    position: absolute;
    border-radius: 50%;

    @include respond-to(xs) {
      width: 25px;
      height: 25px;
      top: 10px;
      right: 10px;
      border: 2px solid #fff;
    }

    img {
      display: block;
      width: 50%;
    }
  }

  &__body {
    @include vw($padding: 60 60 20);

    @include respond-to(xs) {
      padding: 20px 10px 20px;
    }
  }

  .progress {
    @include respond-to(xs) {
      border-radius: 8px;
      background: #e8e8e8;
      height: 8px;
      width: 90%;
      margin: 5px auto;
    }

    &-bar {
      background: #52246e;
      border-radius: 8px;
      height: 100%;
      transition: all 0.4s ease;
      // width: calc(100% / 6);
      width: 0;
    }
  }

  &__slider {
    position: relative;
  }

  &__slide {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    z-index: 0;
    visibility: hidden;
    transition: all 0.5s ease-out;

    &:first-child {
      position: relative;
    }
    &.start-qw {
      position: relative;
      @include respond-to(xs) {
        position: absolute;
      }
    }
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__question {
    @include vw(
      $font-size: 36,
      $width: 680,
      $margin: 0 auto 30,
      $border-bottom: 2 solid #767686,
      $padding: 0 20 25
    );
    max-width: 100%;
    color: #1f1f2a;
    line-height: 1.05;
    font-family: $bold;

    @include respond-to(xs) {
      width: 680px;
      margin: 0 auto 0px;
      padding: 0 20px 27px;
      font-size: 18px;
      border-bottom: 0;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    @include vw($width: 860, $margin: 0 auto);
    max-width: 100%;

    @include respond-to(xs) {
      flex-wrap: wrap;
    }
  }

  &__btn {
    @include vw($width: 379, $height: 65);
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include vw(
      $font-size: 32,
      $border: 2 solid $brand,
      $border-radius: 60,
      $margin-bottom: 25
    );
    font-family: $bold;
    color: #1f1f2a;
    transition: all 0.6s ease;
    @include respond-to(xs) {
      min-width: 149px;
      width: auto;
      height: 50px;
      font-size: 18px;
      border: 2px solid #52246e;
      border-radius: 60px;
      margin: 0 auto 10px;
      padding: 0 10px;
    }

    // &:hover {
    //   box-shadow: 0px 0px 19px 5px rgba(106, 97, 122, 0.4);
    // }

    &:hover {
      background: $brand;
      color: #fff;
      // box-shadow: 0px 0px 19px 5px rgba(106, 97, 122, 0.4);
    }
  }

  &__navigate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include vw($margin-top: 0);
  }

  &__pages {
    flex-basis: 33%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;

    @include respond-to(xs) {
      visibility: hidden;
    }

    &-item {
      border-radius: 50%;
      @include vw($width: 24, $height: 24, $margin-right: 13);
      background: #e7e7e7;
      display: block;
      position: relative;
      transition: all 0.5s ease;
      z-index: 2;

      &:before {
        content: '';
        display: block;
        @include vw($height: 2, $width: 13);
        background: #e7e7e7;
        top: 50%;
        left: 0;
        position: absolute;
        transform: translate(-100%, -50%);
        transition: all 0.5s ease;
        z-index: 0;
      }

      &:first-child {
        z-index: 7;

        &:before {
          display: none;
        }
      }

      &:nth-child(2) {
        z-index: 6;
      }

      &:nth-child(3) {
        z-index: 5;
      }

      &:nth-child(4) {
        z-index: 4;
      }

      &.is-active {
        background: $brand;
        transform: scale(1.1);

        // &:before {
        //     background: $brand;
        // }
      }
    }
  }

  &__next {
    flex-basis: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include vw($padding-right: 112);

    @include respond-to(xs) {
      padding-right: 10px;
      justify-content: flex-end;
    }
    &.hide {
      display: none;
    }
    &.last-step {
      .quiz__next-btn {
        & > img {
          display: none;
        }

        color: #fff;
        @include vw(
          $font-size: 24,
          $border-radius: 45,
          $height: 64,
          $width: 260
        );
        font-family: $bold;
        background: $brand;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s cubic-bezier(0.4, 0, 1, 1);
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
        @include respond-to(xs) {
          border-radius: 45px;
          font-size: 16px;
          width: 172px;
          height: 42px;
          padding: 0;
        }
        span {
          position: relative;
          display: block;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include vw($right: -30, $width: 8, $height: 13);
            background: url(/images/shevron.png) no-repeat center center;
            transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
          }
        }

        &:hover {
          color: $brand;
          background: #fff;
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);

          span {
            &:before {
              content: '';
              top: 50%;
              transform: translateY(-50%);
              @include vw($right: -35);
              background: url(/images/shevron-dark.png) no-repeat center center;
            }
          }
        }
      }
    }
  }

  &__next-btn {
    flex-grow: 0;
    flex-shrink: 0;
    @include vw($width: 54, $height: 54, $border: 2 solid $brand);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-to(xs) {
      width: 35px;
      height: 35px;
      border: 0;
      box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.1);
    }

    span {
      display: none;
    }

    img {
      width: 20%;
    }
  }

  &__slide {
    &.first-step {
      text-align: center;
      .first-step {
        &__title {
          @include respond-to(xs) {
            font-size: 26px;
            color: #000;
            margin: 10px auto;
          }
        }
        &__subtitle {
          @include respond-to(xs) {
            font-size: 26px;
            color: #000;
            margin: 0px auto;
          }
        }
        &__footer {
          color: #000;
          font-size: 18px;
          font-family: $light;
          margin-bottom: 13px;
        }
        &__img {
          text-align: center;
          img {
            width: 54%;
          }
        }
      }
      .btn {
        margin: 0 auto;
      }
    }
    &-win {
      .quiz__question {
        @include vw($margin-bottom: 10);

        @include respond-to(xs) {
          padding: 0 20px 0px;
        }
      }

      .quiz__win-text {
        font-family: $light;
        color: #1f1f2a;
        @include vw($font-size: 24, $margin-bottom: 10);

        @include respond-to(xs) {
          font-size: 18px;
        }
      }

      .quiz__win-code {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include vw(
          $margin-bottom: 30,
          $width: 510,
          $margin: 0 auto 10,
          $border: 4 dashed $brand,
          $height: 150,
          $padding: 10
        );

        @include respond-to(xs) {
          width: 63%;
          margin: 0 auto 10px;
          border: 2px dashed #52246e;
          height: 80px;
          padding: 10px;
        }

        span {
          @include vw($font-size: 125, $letter-spacing: 10);
          color: $brand;
          line-height: 1;

          @include respond-to(xs) {
            font-size: 41px;
            letter-spacing: 2px;
          }

          &.grey {
            background: #eee;
          }
        }
      }

      .copy {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include vw($width: 33, $right: 15);

        @include respond-to(xs) {
          width: 18px;
          right: 3px;
        }
      }

      .woo {
        @include respond-to(xs) {
          text-align: center;
          font-size: 32px;
          color: $brand;
          margin-bottom: 14px;
        }
      }

      .quiz__win-link {
        font-family: $light;
        color: #1f1f2a;
        @include vw($font-size: 24);

        @include respond-to(xs) {
          font-size: 18px;
        }
      }
    }
  }
}

.order {
  position: fixed;
  z-index: 999;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  overflow: scroll;
  height: 100%;
  background: #fff;
  transform: translateY(100%);
  // transform: translateY(0%);
  transition: transform 1s ease-out;
  background: #111423;
  .site-width {
    @include respond-to(xs) {
      padding: 0 33px;
    }
  }

  &__bg {
    background: #111423 url('/images/order-bg.png') bottom center no-repeat;
    background-size: contain;
    @include vw($padding-bottom: 300);

    @include respond-to(xs) {
      padding-bottom: 120px;
    }
  }

  &__header {
    @include vw($border-bottom: 2 solid #e7e7e7, $padding: 40 0 15);

    text-align: center;
    z-index: 999;

    @include respond-to(xs) {
      border-bottom: 2px solid #e7e7e7;
      padding: 12px 0 8px;
      margin-bottom: 15px;
    }
  }

  &__logo {
    display: block;
    margin: 0 auto;
    @include vw($width: 250);

    @include respond-to(xs) {
      width: 165px;
    }

    img {
      width: 100%;
    }
  }

  &__inner {
    @include respond-to(xs) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__title {
    text-align: center;
    color: #fff;
    @include vw($font-size: 34, $padding: 40 0 0, $margin-bottom: 10);

    @include respond-to(xs) {
      font-size: 21px;
      padding: 0;
      margin-bottom: 5px;
    }
  }

  &__subtitle {
    color: #fff;
    text-align: center;
    @include vw($font-size: 21);

    @include respond-to(xs) {
      font-size: 14px;
      width: 76%;
      margin: 0 auto;
      font-family: $bold;
    }
  }

  .order__subscr {
    @include respond-to(xs) {
      order: 2;
      width: 100%;
      margin-top: 18px;
    }
  }

  .order__bottle {
    position: relative;
    @include respond-to(xs) {
      order: 3;
    }
    &-wrap {
      position: relative;
    }
    &--item {
      transition: all 1s ease;
      opacity: 0;
      visibility: hidden;
      z-index: 0;
      &.is-active {
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }
    }
    &--subscr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
    }
    &--time {
    }
    &--one {
      position: relative;
      &.is-visible {
        z-index: 1;
        opacity: 1;
        visibility: visible;
      }
      .order__bottle-wrap {
        @include vw($margin-bottom: 50, $margin-top: 86, $width: 813);
        margin: 0 auto;
        @include respond-to(xs) {
          width: 100%;

          // margin: 0 auto 50px;
          // display: flex;
          // justify-content: space-between;
          // padding-top: 45px;
          margin: 0 auto 0px;
        }
      }
      .bottles-buy {
        display: flex;
        // justify-content: space-between;
        justify-content: flex-end;

        transition: all 1s ease;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        z-index: 0;
        margin: 0;
        @include respond-to(xs) {
          display: none;
          transition: none;
        }
        &.is-visible {
          z-index: 1;
          opacity: 1;
          visibility: visible;
          @include respond-to(xs) {
            display: flex;
            position: static;
          }
        }
        @include respond-to(xs) {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 0 auto 50px;
          width: 100%;
          display: flex;
          justify-content: space-around;
          padding-top: 45px;
          margin: 0 auto 0px;
        }
      }
    }
  }

  .subscribe {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
    @include vw($padding: 40 40 20, $padding-top: 95, $width: 1170);

    @include respond-to(xs) {
      padding: 30px 0 20px;
      margin-top: 24px;
      width: 100%;
    }

    &__card {
      flex-basis: 49%;
      background: #fff;
      @include vw($border: 5 solid, $border-radius: 8, $padding: 34 20);
      border-color: transparent;
      background: url('/images/order-cloud.png') top left no-repeat;
      background-size: 100%;
      transition: $trans;
      position: relative;
      @include respond-to(xs) {
        border: 2px solid;
        border-radius: 8px;
        flex-basis: 49%;
        padding: 21px 6px 5px;
        background: url('/images/order-cloud.png') top left no-repeat;
        background-size: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        z-index: 0;
        background: rgba(27, 30, 47, 0.6);
        width: 100%;
        height: 100%;
        @include vw($margin: -34 -20);
        @include respond-to(xs) {
          margin: -21px -6px;
        }
      }
      &:hover {
        border-color: $brand;
      }
      &.is-active {
        border-color: $brand;
      }
      &--plus {
        position: relative;
        background: url('/images/order-cloud-1.png') top left no-repeat;
        background-size: 100%;
        .subscribe__card-header {
          position: absolute;
          width: 96%;
          transform: translate(-50%, -100%);
          background: #fbcc5b;
          color: #b29244;
          top: 0;
          left: 50%;
          @include vw($font-size: 30);
          font-family: $bold;
          text-align: center;
          @include vw(
            $padding: 10,
            $border-top-left-radius: 8,
            $border-top-right-radius: 8
          );
          z-index: 0;

          @include respond-to(xs) {
            font-size: 16px;
            padding: 5px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
        }

        .subscribe__title {
          @include vw($margin-bottom: 5);

          @include respond-to(xs) {
            font-size: 26px;
            margin-bottom: 53px;
            color: #fff;
            margin-bottom: 0px;
          }
        }

        .subscribe__subtitle {
          text-align: center;
          font-family: $reg;
          color: #fff;
          position: relative;
          @include vw($font-size: 18, $margin-bottom: 42);

          @include respond-to(xs) {
            font-size: 12px;
            margin-bottom: 31px;
            font-size: 12px;
            margin-bottom: 10px;
          }
        }

        .subscribe__list-item {
          color: #7ecdca;
          text-decoration: none;
        }
      }
    }

    &__subtitle {
      text-align: center;
      font-family: $reg;
      color: #fff;
      position: relative;
      @include vw($font-size: 18, $margin-bottom: 42);

      @include respond-to(xs) {
        font-size: 12px;
        margin-bottom: 31px;
        font-size: 12px;
        margin-bottom: 10px;
      }
    }

    &__title {
      position: relative;
      @include vw($font-size: 42, $margin-bottom: 5);
      color: #fff;
      text-align: center;

      @include respond-to(xs) {
        font-size: 26px;
        margin-bottom: 0px;
      }
      &--yellow {
        color: #fbcc5b;
      }
    }

    &__price {
      position: relative;
      text-align: center;
       @include vw($margin-bottom: 30);
@include respond-to(xs){
   margin-bottom: 20px;
}
        span {
          display: block;
        }

        .price {
          font-family: $bold;
          color: #fff;
          @include vw($font-size: 50);
          @include respond-to(xs){
            font-size: 34px;
          }
        }

        .small {
          font-family: $reg;
         @include vw($font-size: 18);
          @include respond-to(xs){
             font-size: 12px;
          }
        }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      &-item {
        flex-basis: 48%;
        @include vw($font-size: 18, $margin-bottom: 20);
        font-family: $bold;
        color: #c0575e;
        margin-right: 2%;

        @include respond-to(xs) {
          margin-right: 0;
          margin-bottom: 5px;
          font-size: 12px;
          flex-basis: 100%;
          font-family: $reg;
          font-size: 12px;
          margin-bottom: 13px;
          padding-left: 10px;
          text-decoration: line-through;
        }
      }
    }

    &__box {
      position: absolute;
      width: 124px;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(87%, 20%);
      @include respond-to(xs) {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    &__btns {
      display: flex;
      justify-content: flex-end;
      margin: 0 auto;
      @include vw($width: 1080);

      @include respond-to(xs) {
        display: none;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-family: $bold;
      &-wrap {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        justify-content: center;
        display: flex;
      }
      @include vw(
        $font-size: 21,
        $border: 4 solid $brand,
        $border-radius: 8,
        $width: 260,
        $height: 67,
        // $margin-left: 10
      );

      @include respond-to(xs) {
      }
    }
  }

  .bottles {
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
    position: relative;
    opacity: 1;
    visibility: visible;
    transition: all 1s ease;
    z-index: 1;

    @include vw($margin-bottom: 50, $margin-top: 86, $width: 813);

    &.is-hide {
      z-index: 0;
      opacity: 0;
      visibility: hidden;
      @include respond-to(xs) {
        display: none;
      }
    }
    @include respond-to(xs) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto 50px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding-top: 45px;
      margin: 0 auto 0px;
    }

    &__card {
      text-align: center;
      background: #1b1e2f;
      position: relative;
      transition: all 1s ease;
      opacity: 1;
      visibility: visible;

      @include vw(
        $border: 4 solid transparent,
        $padding: 31 10 5,
        $border-radius: 8
      );

      @include respond-to(xs) {
        border: 2px solid transparent;
        border-radius: 8px;
        padding: 9px 3px 0;
        box-shadow: 0 0 4px 20px rgba(0, 0, 0, 0.02);
        height: 151px;
      }
      &.is-active,
      &:hover {
        @include vw($border: 4 solid $brand);
      }
      &.is-hide {
        opacity: 0;
        visibility: hidden;
      }
      &-icon {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        @include vw($width: 50);
        @include respond-to(xs) {
          width: 20px;
        }
      }
      .back-link {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-100%, -50%);
        @include vw($width: 54, $height: 54, $left: -30);
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.4s ease;

        @include respond-to(xs) {
          width: 32px;
          height: 32px;
          margin-left: 10px;
          left: -12px;
        }
        img {
          transform: rotate(90deg);
          width: 43%;
        }
      }
      &--family {
        position: relative;

        .bottles__header {
          position: absolute;
          width: 96%;
          transform: translate(-50%, -100%);
          background: #fbcc5b;
          color: #b29244;
          top: 0;
          left: 50%;
          @include vw($font-size: 22);
          font-family: $bold;
          text-align: center;
          @include vw(
            $padding: 10,
            $border-top-left-radius: 8,
            $border-top-right-radius: 8
          );
          z-index: -1;

          @include respond-to(xs) {
            font-size: 16px;
            padding: 2px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
        }
      }
    }
    &__item {
      flex-basis: 32%;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 1.495%;
      &:last-child {
        margin-right: 0;
      }
      @include respond-to(xs) {
        margin-right: 1.495%;
      }
    }
    &__title {
      color: #fff;
      @include vw($font-size: 42, $margin-bottom: 10);

      @include respond-to(xs) {
        font-size: 18px;
        font-family: $bold;
      }
    }

    &__subtitle {
      font-family: $reg;
      color: #fff;
      color: #682d8c;
      @include vw($margin-bottom: 44, $font-size: 21);

      @include respond-to(xs) {
        font-size: 10px;
        margin-bottom: 13px;
      }
    }

    &__img {
      width: 96%;
      margin: 0 auto;

      @include respond-to(xs) {
        width: 84%;
      }

      img {
        width: 100%;
      }
    }

    &__btns {
      display: flex;
      justify-content: center;
      @include vw($margin-bottom: 45);

      @include respond-to(xs) {
        display: none;
      }
    }
  }
  .buy {
    display: flex;
    flex-direction: column;
    position: relative;
    @include respond-to(xs) {
      padding-left: 10px;
    }

    &__title {
      color: #fff;
      @include vw($font-size: 42, $padding: 25 0 15, $margin-bottom: 10);
      position: relative;
      @include respond-to(xs) {
        font-size: 26px;
        margin-bottom: 10px;
        padding: 10px 0 5px;
      }
      &:after {
        content: '';
        height: 2px;
        width: 40%;
        background: #e7e7e7;
        display: block;
        bottom: 0;
        left: 0;
        position: absolute;
      }
    }
    &__info {
      &-row {
        font-family: $bold;
        color: #000000;
        line-height: 1.3;
        @include vw($font-size: 20, $margin-bottom: 5);
        &--sm{
          @include vw($font-size: 18, $margin-bottom: 5);

          font-family: $reg;
          span{
                      color: #fff;
          }
        }
        @include respond-to(xs) {
          font-size: 12px;
        }
      }

      &-title {
        font-family: $bold;
        color: #fff;
        @include vw($font-size: 18);
        @include respond-to(xs) {
          font-size: 12px;
        }
      }
      &-params {
        color: #7ecdca;
        @include respond-to(xs) {
          font-size: 12px;
        }
      }
      &-cancel {
        font-family: $bold;
        color: #000000;
        @include vw($font-size: 18);
        color: #c0575e;
        @include respond-to(xs) {
          font-size: 12px;
        }
      }
    }
    &__btn {
      @include vw($margin: 20 0 20);
      @include respond-to(xs) {
        margin: auto 0 5px;
      }
      .btn {
        @include respond-to(xs) {
          width: 152px;
        }
      }
    }
  }
  &__logos {
    @include vw($width: 495, $margin: 0 auto);

    @include respond-to(xs) {
      order: 4;
      width: 45%;
      margin-top: 10px;
    }

    img {
      width: 100%;
    }
  }

  &__btn {
    @include respond-to(xs) {
      order: 3;
      order: 2;
      margin-top: 30px;
      text-align: center;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }

    .btn {
      @include respond-to(xs) {
        border-radius: 45px;
        font-size: 23px;
        width: 259px;
        height: 66px;
        padding: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.quality {
  @include respond-to(xs) {
    background: #0c0c11;
    position: relative;
    margin-top: -1px;
    padding-top: 106px;

    .title {
      font-size: 51px;
      color: #fff;
      width: 90%;
      margin-bottom: 50px;
      width: 100%;
      position: relative;
      opacity: 0.2;
    }
    &__img {
      position: absolute;
      bottom: -130px;
      right: -200px;
      width: 420px;
      // transform: rotate3d(0, 0, 1, 15deg);
      opacity: 0.2;
      img {
        width: 100%;
      }
    }
    &__subtitle {
      font-family: $bold;
      font-size: 21px;
      margin-bottom: 20px;
      opacity: 0.2;
    }

    &__content {
      font-family: $light;
      font-size: 18px;
      opacity: 0.2;

      a {
        color: #fff;
        font-family: $bold;
        line-height: 1.6;

        &:hover {
          color: #fff;

          text-decoration: underline;
        }
      }
    }
  }
}
.letter {
  opacity: 0;
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  display: none;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  background: #fff;
  z-index: 99;
  overflow: hidden;
  // border-top-right-radius: 8px;
  visibility: hidden;
  transition: $trans;
  opacity: 0;
  @include vw($border-radius: 8, $width: 800);
  @include respond-to(xs) {
    // top: 120px;
    width: 81%;
    border-radius: 8px;
  }

  &.is-visible {
    transform: translate(-50%, -50%);
    visibility: visible;
    opacity: 1;
  }
  &__header {
    background: #682d8c;
    position: relative;
    @include vw($padding: 20);
    @include respond-to(xs) {
      padding: 20px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url('/images/modal-saffron-bg.svg') center center no-repeat;
      background-size: contain;
      @include vw($width: 180, $height: 180);
      @include respond-to(xs) {
        width: 110px;
        height: 110px;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      @include vw($width: 180, $height: 180);
      background: url('/images/modal-saffron-bg.svg') center center no-repeat;
      background-size: contain;
      @include respond-to(xs) {
        width: 110px;
        height: 110px;
      }
    }
    &-close {
      @include vw($top: 15, $right: 15, $font-size: 30);
      position: absolute;
      z-index: 9;
      cursor: pointer;
      opacity: 0.7;
      display: none;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
      @include respond-to(xs) {
        top: 10px;
        right: 10px;
      }
    }
    &-icon {
      @include vw($width: 150, $height: 150, $border: 4 solid #fff);
      margin: 0 auto;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 86%;
        @include vw($margin-top: 30);
        // margin-top: 27px;
        @include respond-to(xs) {
          margin-top: 10px;
        }
      }
      @include respond-to(xs) {
        width: 77px;
        height: 77px;
        border: 4px solid #fff;
      }
    }
  }
  &__body {
    text-align: center;
    @include vw($padding: 30 40 70);
    @include respond-to(xs) {
      padding: 10px 30px 70px;
    }
  }
  &__title {
    font-family: $bold;
    color: #000;
    position: relative;
    @include vw($font-size: 42, $padding-bottom: 15, $margin-bottom: 35);
    @include respond-to(xs) {
      padding-bottom: 15px;
      margin-bottom: 15px;
      font-size: 26px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      height: 1px;
      background: #4d3177;
      width: 50%;
      display: block;
    }
  }
  &__list {
    @include vw($margin-bottom: 20);
    &-item {
      font-family: $bold;
      color: #000;
      line-height: 1.3;
      text-align: left;
      position: relative;
      @include vw($font-size: 28, $margin-bottom: 13, $padding-left: 30);
      @include respond-to(xs) {
        font-size: 14px;
        margin-bottom: 7px;
        padding-left: 20px;
      }
      &:before {
        content: '';
        display: block;
        background: url(/images/list.png) center center no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        @include vw($width: 25, $height: 25, $top: 4);
        @include respond-to(xs) {
          width: 10px;
          height: 10px;
          top: 5px;
        }
      }
    }
  }
  &__text {
    color: #000;
    line-height: 1.3;
    text-align: left;
    // @include vw($font-size: 24, $margin-bottom: 30);
    // font-family: $light;
    // @include vw($font-size: 32, $margin-bottom: 30);
    @include vw($font-size: 28, $margin-bottom: 30);
    // color: #fff;
    font-family: $light;
    line-height: 1.3;
    @include respond-to(xs) {
      font-family: $light;
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
  &__link {
    font-family: $light;
    color: $brand;
    line-height: 1.3;
    @include vw($font-size: 24);
    @include respond-to(xs) {
      font-size: 18px;
    }
  }
  &__next-link {
    display: flex;
    text-align: center;
    justify-content: center;
    @include vw($margin-bottom: 20, $font-size: 24);
    &.js-next-slide {
      @include vw($padding-left: 20);
      color: #333;
      &:hover {
        color: $brand;
      }
    }
    color: $brand;
    @include respond-to(xs) {
      font-size: 16px;
    }
    img {
      @include vw($margin-left: 20);
      @include respond-to(xs) {
        margin-left: 5px;
      }
    }
  }
  &__item {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 94vh;
    @include respond-to(xs) {
    }
    // &[data-modal='saffron'] {
    //   .modal__header {
    //     &:before {
    //       background: url('/images/modal-saffron-bg.svg') center center
    //         no-repeat;
    //       background-size: contain;
    //       @include vw($width: 160, $height: 160);
    //       @include respond-to(xs) {
    //         width: 90px;
    //         height: 90px;
    //       }
    //     }
    //     &:after {
    //       @include vw($width: 160, $height: 160);
    //       background: url('/images/modal-saffron-bg.svg') center center
    //         no-repeat;
    //       background-size: contain;
    //       @include respond-to(xs) {
    //         height: 90px;
    //         width: 90px;
    //       }
    //     }
    //   }
    // }
    // &[data-modal='magnesium'] {
    //   .modal__header {
    //     &:before {
    //       // width: 90px;
    //       // height: 90px;
    //       background: url('/images/modal-magn-bg.svg') center center no-repeat;
    //       background-size: contain;
    //       @include vw($width: 140, $height: 140);
    //       @include respond-to(xs) {
    //         height: 90px;
    //         width: 90px;
    //       }
    //     }
    //     &:after {
    //       // height: 90px;
    //       // width: 90px;
    //       @include vw($width: 110, $height: 110);
    //       background: url('/images/modal-magn-bg.svg') center center no-repeat;
    //       background-size: contain;
    //       @include respond-to(xs) {
    //         height: 90px;
    //         width: 90px;
    //       }
    //     }
    //   }
    // }
    // &[data-modal='ash'] {
    //   .modal__header {
    //     &:before {
    //       // width: 90px;
    //       // height: 110px;
    //       @include vw($width: 110, $height: 130);
    //       background: url('/images/modal-ash-bg.svg') center center no-repeat;
    //       background-size: contain;
    //       @include respond-to(xs) {
    //         width: 90px;
    //         height: 110px;
    //       }
    //     }
    //     &:after {
    //       height: 110px;
    //       width: 90px;
    //       @include vw($width: 110, $height: 130);
    //       background: url('/images/modal-ash-bg.svg') center center no-repeat;
    //       background-size: contain;
    //       @include respond-to(xs) {
    //         height: 110px;
    //         width: 90px;
    //       }
    //     }
    //   }
    // }
    // &[data-modal='citrus'] {
    //   .modal__header {
    //     &:before {
    //       background: url('/images/modal-citrus-bg.svg') center center no-repeat;
    //       background-size: contain;
    //       @include vw($width: 110, $height: 130);
    //       @include respond-to(xs) {
    //         width: 90px;
    //         height: 110px;
    //       }
    //     }
    //     &:after {
    //       @include vw($width: 110, $height: 130);
    //       background: url('/images/modal-citrus-bg.svg') center center no-repeat;
    //       background-size: contain;
    //       @include respond-to(xs) {
    //         height: 110px;
    //         width: 90px;
    //       }
    //     }
    //   }
    // }
  }
  &-suppl {
    .modal__body {
      @include vw($padding: 0 0 40);
    }
    .modal__img {
      width: 100%;
      @include vw($margin-top: -40);
      img {
        width: 100%;
      }
    }
    &__footer {
      color: $brand;
      text-align: left;
      @include vw($padding: 20 70);
      @include respond-to(xs) {
        padding: 20px;
      }
      &-title {
        @include vw($font-size: 40, $margin-bottom: 20);

        font-family: $bold;
        @include respond-to(xs) {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
      &-text {
        font-family: $light;
        @include vw($font-size: 24, $margin-bottom: 30);
        @include respond-to(xs) {
          font-size: 16px;
        }
      }
    }
  }
}
