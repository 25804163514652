@import "system/variables";
/******  HELPERS  ******/
.color-brand {
    color: $brand;
  }
  
  .color-white {
    color: #fff;
  }
  
  .text-center {
    text-align: center;
  }

  .flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.w-100 {
    width: 100%;
}
  