@import "system/variables";
@import "system/mixin";

html,
body {
    //   overflow-x: hidden;
    //   width: 100vw;

    @include respond-to(xs) {
        overflow-x: hidden;
        width: 100vw;
    }
}



body {
    font-family: $ff;
    color: #fff;
    line-height: 1.2;
    // background: linear-gradient(180deg, rgba(9,9,12,1) 0%, rgba(19,19,26,1) 30%, rgba(23,23,31,1) 45%, rgba(0,0,0,1) 55%, rgba(31,31,38,1) 70%, rgba(33,33,38,1) 74%, rgba(57,57,67,1) 78%, rgba(108,108,121,1) 82%, rgba(193,193,216,1) 100%);
    @include respond-to(xs) {
        font-size: 16px;
    }
}

b,
strong {
    font-family: $ff;
    font-weight: $bold;
}

img {
    max-width: 100%;
}
.color-yellow{
color: #FDFF00;
}
.bold{
    font-family: $bold;
}
.show-desktop{
        display: block !important;
    @include respond-to(xs){
    display: none !important;
    }
}
.show-mobile{
    display: none !important;
    @include respond-to(xs){
        display: block !important;
    }
}
.site-width {
    max-width: 100%;
    @include vw($width: 1660,
        $padding: 0 15);
    //  width: 1655px;
    margin: 0 auto;

    //  padding: 0 15px;
   @include respond-to(xs){
       padding: 0 35px;
   }
}
.content-width {
    max-width: 100%;
    @include vw($width: 1400,
        $padding: 0 15);
    //  width: 1655px;
    margin: 0 auto;
    @include respond-to(xs){
        padding: 0 35px;
    }
}


.title {
    font-family: $ff;
    line-height: 1.1;
    color: #fff;
    @include vw($font-size: 128,
        $margin-bottom: 20);

    @include respond-to(xs) {
        font-size: 47px;

        line-height: 1;
    }
}
.btn {
    color: #fff;
    @include vw($font-size: 24,
        $border-radius: 45,
        $height: 64,
        $width: 260);
    font-family: $bold;
    background: $brand;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;;
    transition: all .3s cubic-bezier(.4, 0, 1, 1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
    border: none;
    overflow: hidden;
    position: relative;
    &:before{
        content: '';
        @include vw($height: 68,
        $width: 264);
        background: #fff;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .3s cubic-bezier(.4, 0, 1, 1);
        transform: translateX(-100%);
    }
    @include respond-to(xs){
        font-size: 16px;
        width: 172px;
        height: 42px;
        padding: 0px 0px;
    }
    span{
        position: relative;
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include vw($right: -30,
            $width: 8,
            $height: 13);
            background:  url(/images/shevron.png) no-repeat center center;
            background-size: contain;
            transition: all .3s cubic-bezier(.4, 0, 1, 1);
            @include respond-to(xs){
                right: -20px;
                width: 8px;
                height: 13px;
            }
        }
    }
    &:hover {
        color: $brand;
        &:before{
            transform: translateX(0);
        }
        // color: $brand;
        // background:  #fff;
        // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
        span{
            &:before{
                content: '';
                // top: 50%;
                background:  url(/images/shevron-dark.png) no-repeat center center;
                background-size: contain;
            }
        }
    }

}

