
@font-face {
    font-family: 'Mont Demo';
    src:
      url('/fonts/Mont-DEMO.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gill-Sans-Nova-Regular';
    src:
      url('/fonts/Gill-Sans/GillSans.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gill-Sans-Nova-Bold';
    src:
      url('/fonts/Gill-Sans/GillSans-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gill-Sans-Nova-Bold-Italic';
    src:
      url('/fonts/Gill-Sans/GillSans-BoldItalic.ttf') format("truetype");
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Gill-Sans-Nova-Light';
    src:
      url('/fonts/Gill-Sans/GillSans-Light.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gill-Sans-Nova-Light-Italic';
    src:
      url('/fonts/Gill-Sans/GillSans-LightItalic.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
  }